import { useCallback, useEffect, useMemo, useRef } from "react";
import ChartCard from "./ChartCard";
import { ChartComponentProps } from "./types";
import API from "../../../api";
import { HeatMapChartInfo } from "../../../interface/analytics";
import useApi from "../../../hooks/useApi";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const HeatMapChart = ({
  chartDetails,
  className,
  filters,
}: ChartComponentProps) => {
  const apiCall = useCallback(
    () => API.getChartDetails<HeatMapChartInfo>(chartDetails.key, filters),
    [chartDetails, filters]
  );
  const { isLoading, isError, response, fetchData } = useApi(apiCall);

  const prevFilters = useRef(filters);

  useEffect(() => {
    const isFiltersEmpty = !filters || Object.keys(filters).length === 0;

    if (
      !isFiltersEmpty &&
      JSON.stringify(prevFilters.current) !== JSON.stringify(filters)
    ) {
      console.log(
        "[API CALL] Fetching HorizontalBarChart data with filters:",
        filters
      );
      prevFilters.current = filters;
      fetchData();
    }
  }, [filters]);

  const data = useMemo(() => {
    if (response && response.chartData) {
      return response.chartData.map((item, i) => {
        return {
          id: response.parameters.yLabels[i],
          data: item.map((val, j) => ({
            x: response.parameters.xLabels[j],
            y: val.value,
          })),
        };
      });
    }
    return [];
  }, [response]);

  const isDarkMode: boolean = useSelector(
    (state: RootState) => state.appReducer.isDarkMode
  );
  const colorChange = () => {
    if (!isDarkMode) {
      return "#132539";
    } else {
      return "rgb(255 255 255)";
    }
  };
  const tooltipStyle = {
    backgroundColor: "#fff",
    color: "#000",
    display: "flex",
    padding: ".5rem",
    borderRadius: "5px",
    boxShadow: "0 0 5px rgba(0,0,0,0.2)",
    fontSize: "14px",
    columnGap: ".5rem",
    alignItems: "center",
  };
  const theme = {
    axis: {
      ticks: {
        line: {
          stroke: colorChange(),
        },
        text: {
          fill: colorChange(),
        },
      },
      legends: {
        text: {
          fill: colorChange(),
        },
      },
    },
  };

  return (
    <ChartCard
      title={chartDetails.title}
      className={`${className} ${chartDetails.type}`}
      isLoading={isLoading}
      isError={isError}
      noData={data.length === 0}
      onRetry={fetchData}
    >
      <ResponsiveHeatMap
        data={data}
        margin={{ top: 30, right: 30, bottom: 60, left: 90 }}
        valueFormat=">-.0s"
        xInnerPadding={0.05}
        yInnerPadding={0.05}
        colors={{
          type: "diverging",
          scheme: "cividis",
          colors: [
            "#D1F0E4",
            "#A3E1CA",
            "#93DCC1",
            "#84D7B8",
            "#74D2B0",
            "#56C89E",
            "#56C89E",
            "#46C395",
            "#3CB98B",
            "#37A87E",
            "#287B5D",
          ],
        }}
        labelTextColor={{
          from: "colors",
          modifiers: [["darker", 5]],
          // theme: isDarkMode?'dark' : 'light',
        }}
        tooltip={({ cell }) => (
          <div style={tooltipStyle}>
            <div
              style={{
                backgroundColor: cell.color,
                width: "15px",
                height: "15px",
              }}
            ></div>
            <div>{cell.serieId}</div>
            <div>{cell.data.x}</div>:
            <div style={{ fontWeight: "bold" }}>{cell.formattedValue}</div>
          </div>
        )}
        emptyColor="#555555"
        legends={[
          {
            anchor: "bottom",
            translateX: 0,
            translateY: 40,
            length: 400,
            thickness: 8,
            direction: "row",
            tickPosition: "after",
            tickSize: 3,
            tickSpacing: 4,
            title: "Value →",
            titleAlign: "start",
            titleOffset: 4,
          },
        ]}
        theme={theme}
      />
    </ChartCard>
  );
};

export default HeatMapChart;
