import { useCallback, useEffect, useMemo, useRef } from "react";
import ChartCard from "./ChartCard";
import { ChartComponentProps } from "./types";
import { ResponsiveBar } from "@nivo/bar";
import API from "../../../api";
import { BarChartInfo } from "../../../interface/analytics";
import useApi from "../../../hooks/useApi";

const BarChart = ({
  chartDetails,
  className,
  filters,
}: ChartComponentProps) => {
  const apiCall = useCallback(
    () => API.getChartDetails<BarChartInfo>(chartDetails.key, filters),
    [chartDetails, filters]
  );
  const { isLoading, isError, response, fetchData } = useApi(apiCall);

  const prevFilters = useRef(filters);

  useEffect(() => {
    const isFiltersEmpty = !filters || Object.keys(filters).length === 0;

    if (
      !isFiltersEmpty &&
      JSON.stringify(prevFilters.current) !== JSON.stringify(filters)
    ) {
      console.log(
        "[API CALL] Fetching HorizontalBarChart data with filters:",
        filters
      );
      prevFilters.current = filters;
      fetchData();
    }
  }, [filters]);

  const data = useMemo(() => {
    if (response && response.chartData) {
      return response.chartData
        .map((val, index) => {
          return {
            id: response.labels[index],
            [response.labels[index]]: val,
          };
        })
        .reverse();
    }
    return [];
  }, [response]);

  return (
    <ChartCard
      title={chartDetails.title}
      className={className}
      isLoading={isLoading}
      isError={isError}
      noData={data.length === 0}
      onRetry={fetchData}
    >
      <ResponsiveBar
        data={data}
        keys={response?.labels}
        colorBy="indexValue"
        indexBy="id"
        margin={{ top: 30, right: 30, bottom: 50, left: 60 }}
        padding={0.3}
        colors="#FF4069"
        labelTextColor="#fff"
        // layout="horizontal"
        tooltip={(e) => (
          <p className="tooltip">
            {response?.parameters.labelString} : {e.formattedValue}
          </p>
        )}
        axisBottom={{
          // legend: response?.data.parameters.labelString,
          tickRotation: -25,
          // renderTick: () => {
          //   return <></>;
          // },
        }}
      />
    </ChartCard>
  );
};

export default BarChart;
