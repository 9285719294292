import { useCallback, useEffect, useRef } from "react";
import API from "../../../../api";
import useApi from "../../../../hooks/useApi";
import { BasicCountChartInfo } from "../../../../interface/analytics";
import { ChartComponentProps } from "../types";
import { Skeleton } from "@mui/material";
import "./BasicCount.scss";
import { Error, Refresh } from "@mui/icons-material";

const BasicCount = ({ chartDetails, filters }: ChartComponentProps) => {
  const apiCall = useCallback(
    () => API.getChartDetails<BasicCountChartInfo>(chartDetails.key, filters),
    [chartDetails, filters]
  );
  const { isLoading, isError, response, fetchData } = useApi(apiCall);

  const prevFilters = useRef(filters);

  useEffect(() => {
    const isFiltersEmpty = !filters || Object.keys(filters).length === 0;

    if (
      !isFiltersEmpty &&
      JSON.stringify(prevFilters.current) !== JSON.stringify(filters)
    ) {
      console.log(
        "[API CALL] Fetching HorizontalBarChart data with filters:",
        filters
      );
      prevFilters.current = filters;
      fetchData();
    }
  }, [filters]);

  return (
    <div className="count-data">
      <p className="value">
        {isError ? (
          <div className="flex-center-y" style={{ fontSize: 12 }}>
            <Error width={20} />
            <div style={{ flex: 1 }}>Error fetching data</div>
            <Refresh
              width={20}
              onClick={fetchData}
              sx={{ cursor: "pointer" }}
            />
          </div>
        ) : isLoading ? (
          <Skeleton animation="wave" variant="rectangular" width={50} />
        ) : (
          response?.value
        )}
      </p>
      <p className="title">{chartDetails.title}</p>
    </div>
  );
};

export default BasicCount;
