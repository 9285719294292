import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ChartCard from "./ChartCard";
import { ChartComponentProps } from "./types";
import ReactECharts from "echarts-for-react";
import API from "../../../api";
import useApi from "../../../hooks/useApi";
import { CHART_TYPE, LineChartInfo } from "../../../interface/analytics";
import { generateCSV } from "../../../helpers/helper";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const LineChart = ({
  chartDetails,
  className,
  filters,
}: ChartComponentProps) => {
  const apiCall = useCallback(
    () => API.getChartDetails<LineChartInfo>(chartDetails.key, filters),
    [chartDetails, filters]
  );
  const { isLoading, isError, response, fetchData } = useApi(apiCall);

  const prevFilters = useRef(filters);

  useEffect(() => {
    const isFiltersEmpty = !filters || Object.keys(filters).length === 0;

    if (
      !isFiltersEmpty &&
      JSON.stringify(prevFilters.current) !== JSON.stringify(filters)
    ) {
      console.log(
        "[API CALL] Fetching HorizontalBarChart data with filters:",
        filters
      );
      prevFilters.current = filters;
      fetchData();
    }
  }, [filters]);

  const handleDownload = (type: "CSV" | "SVG") => {
    if (type === "CSV") {
      const data =
        response?.chartData.reduce(
          (acc, curr) => [...acc, [curr.label, ...curr.value]],
          [] as (string | number)[][]
        ) || [];
      generateCSV(
        ["", ...(response?.labels || [])],
        data,
        response?.parameters.title
      );
    } else {
      // const canvas = chartRef.current?.getCanvas();
      // if (!canvas) {
      //   return;
      // }
      // const canvasData = canvas.toDataURL("image/svg+xml");
      // const blob = new Blob([canvasData], { type: "image/svg+xml" });
      // const downloadUrl = URL.createObjectURL(blob);
      // setDownloadUrl(downloadUrl);
      // const a = document.createElement("a");
      // a.href = downloadUrl;
      // a.download = "chart.svg";
      // a.click();
    }
  };

  const data = useMemo(() => {
    if (response && response.chartType === CHART_TYPE.LINE_CHART) {
      return response.chartData
        .map((item) => {
          return {
            name: item.label,
            data: item.value,
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series",
            },
          };
        })
        .reverse();
    }
    return [];
  }, [response]);

  // @ts-ignore
  // const chartRef = useRef<ReactECharts.EChartsInstance>(null);
  // const [downloadUrl, setDownloadUrl] = useState("");
  const isDarkMode: boolean = useSelector(
    (state: RootState) => state.appReducer.isDarkMode
  );

  const colorChange = () => {
    if (!isDarkMode) {
      return "#132539";
    } else {
      return "#fff";
    }
  };

  const handleExportDataToCSV = () => {
    // const headers: string[] = ["name", "series1", "series2"];
    // const csvContent = convertDataToCSV(data, headers);
    // const fileName = `${lineName}.csv`;
    // downloadCSV(csvContent, fileName);
  };

  const getOption = () => {
    return {
      title: {
        text: chartDetails.title,
        textStyle: {
          color: colorChange(),
          fontWeight: 700,
          fontFamily: "Poppins",
          fontSize: "19px",
        },
      },
      legend: {
        data: response?.chartData.map((item) => item.label),
        top: "10%",
        textStyle: {
          color: colorChange(),
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7988",
          },
        },
      },
      toolbox: {
        show: true,
        feature: {
          dataView: {
            readOnly: true,
          },

          saveAsImage: {},
        },
      },
      grid: {
        top: "20%",
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: response?.labels,
        axisLabel: {
          color: colorChange(),
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          color: colorChange(),
        },
      },
      // series: data.map((seriesItem) => {
      //   return {
      //     ...seriesItem,
      //     areaStyle: {},
      //   };
      // }),
      series: data,
    };
  };
  return (
    <ChartCard
      // title={chartDetails.title}
      className={`${className} ${chartDetails.type}`}
      isLoading={isLoading}
      isError={isError}
      noData={data.length === 0}
      onRetry={fetchData}
      onDownloadClick={handleDownload}
    >
      <ReactECharts
        option={getOption()}
        style={{ height: "100%", width: "100%" }}
      />
    </ChartCard>
  );
};

export default LineChart;
