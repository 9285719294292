import { useCallback, useEffect, useMemo, useRef } from "react";
import ChartCard from "./ChartCard";
import { ChartComponentProps } from "./types";
import { ResponsiveBar } from "@nivo/bar";
import API from "../../../api";
import { BarChartInfo } from "../../../interface/analytics";
import useApi from "../../../hooks/useApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const HorizontalBarChart = ({
  chartDetails,
  className,
  filters,
}: ChartComponentProps) => {
  const isDarkMode: boolean = useSelector(
    (state: RootState) => state.appReducer.isDarkMode
  );
  const colorChange = () => {
    if (!isDarkMode) {
      return "#132539";
    } else {
      return "#fff";
    }
  };

  const theme = {
    axis: {
      ticks: {
        line: {
          stroke: colorChange(),
        },
        text: {
          fill: colorChange(),
        },
      },
    },
  };

  const apiCall = useCallback(
    () => API.getChartDetails<BarChartInfo>(chartDetails.key, filters),
    [chartDetails, filters]
  );
  const { isLoading, isError, response, fetchData } = useApi(apiCall);

  const prevFilters = useRef(filters);

  useEffect(() => {
    const isFiltersEmpty = !filters || Object.keys(filters).length === 0;

    if (
      !isFiltersEmpty &&
      JSON.stringify(prevFilters.current) !== JSON.stringify(filters)
    ) {
      console.log(
        "[API CALL] Fetching HorizontalBarChart data with filters:",
        filters
      );
      prevFilters.current = filters;
      fetchData();
    }
  }, [filters]);

  const data = useMemo(() => {
    if (response && response.chartData) {
      return response.chartData
        .map((val, index) => {
          return {
            id: response.labels[index],
            [response.labels[index]]: val,
          };
        })
        .reverse();
    }
    return [];
  }, [response]);

  return (
    <ChartCard
      title={chartDetails.title}
      className={`${className} ${chartDetails.type}`}
      isLoading={isLoading}
      isError={isError}
      noData={data.length === 0}
      onRetry={fetchData}
    >
      <ResponsiveBar
        data={data}
        theme={theme}
        keys={response?.labels}
        colorBy="indexValue"
        indexBy="id"
        margin={{ top: 0, right: 30, bottom: 50, left: 80 }}
        padding={0.3}
        colors={["#FFDD99", "#A7B0F1", "#74D9E7", "#AAD699", "#74D2B0"]}
        labelTextColor="#fff"
        layout="horizontal"
        tooltip={(e) => (
          <p className="tooltip">
            {response?.parameters.labelString} : {e.formattedValue}
          </p>
        )}
        axisBottom={{
          tickRotation: -25,
        }}
      />
    </ChartCard>
  );
};

export default HorizontalBarChart;
