import { useCallback, useEffect, useRef } from "react";
import ChartCard from "./ChartCard";
import { ChartComponentProps } from "./types";
import API from "../../../api";
import useApi from "../../../hooks/useApi";
import { HtmlChartInfo } from "../../../interface/analytics";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const HtmlChart = ({
  chartDetails,
  className,
  filters,
}: ChartComponentProps) => {
  const apiCall = useCallback(
    () => API.getChartDetails<HtmlChartInfo>(chartDetails.key, filters),
    [chartDetails, filters]
  );
  const { isLoading, isError, response, fetchData } = useApi(apiCall);
  const isDarkMode: boolean = useSelector(
    (state: RootState) => state.appReducer.isDarkMode
  );

  const prevFilters = useRef(filters);

  useEffect(() => {
    const isFiltersEmpty = !filters || Object.keys(filters).length === 0;

    if (
      !isFiltersEmpty &&
      JSON.stringify(prevFilters.current) !== JSON.stringify(filters)
    ) {
      console.log(
        "[API CALL] Fetching HorizontalBarChart data with filters:",
        filters
      );
      prevFilters.current = filters;
      fetchData();
    }
  }, [filters]);

  return (
    <ChartCard
      title={chartDetails.title}
      className={`${className} ${chartDetails.type}`}
      isLoading={isLoading}
      isError={isError}
      noData={!response?.html}
      onRetry={fetchData}
    >
      {response?.html && (
        <div
          dangerouslySetInnerHTML={{ __html: response?.html }}
          data-bs-theme={isDarkMode ? "dark" : "light"}
        ></div>
      )}
    </ChartCard>
  );
};

export default HtmlChart;
