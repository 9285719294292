import { useCallback, useEffect, useRef } from "react";
import API from "../../../api";
import useApi from "../../../hooks/useApi";
import { HighlightedCountChartInfo } from "../../../interface/analytics";
import { ChartComponentProps } from "./types";
import total from "../../../assets/dashboard/total.svg";
import refresh from "../../../assets/dashboard/revisits.svg";
import unique from "../../../assets/dashboard/unique.svg";

const iconMap: Record<string, string> = {
  users: total,
  refresh,
  user: unique,
};

const HighlightedCount = ({
  chartDetails,
  className,
  filters,
}: ChartComponentProps) => {
  const apiCall = useCallback(
    () =>
      API.getChartDetails<HighlightedCountChartInfo>(chartDetails.key, filters),
    [chartDetails, filters]
  );
  const { isLoading, isError, response, fetchData } = useApi(apiCall);

  const prevFilters = useRef(filters);

  useEffect(() => {
    const isFiltersEmpty = !filters || Object.keys(filters).length === 0;

    if (
      !isFiltersEmpty &&
      JSON.stringify(prevFilters.current) !== JSON.stringify(filters)
    ) {
      console.log(
        "[API CALL] Fetching HorizontalBarChart data with filters:",
        filters
      );
      prevFilters.current = filters;
      fetchData();
    }
  }, [filters]);

  return (
    <div className={`userCountCard  ${className}`}>
      {response?.datasets.map((data) => (
        <>
          <section className="userCountCard-left">
            <img src={iconMap[data.icon]} alt={"test"} />
          </section>

          <section className="userCountCard-right">
            <h1>{data.value}</h1>
            <p>{data.title}</p>
          </section>
        </>
      ))}
    </div>
  );
};

export default HighlightedCount;
